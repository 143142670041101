
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {
    }
})
export default class Guide extends Vue {

    openedFaqs: number[] = [];
    faqs: any[] = [];
    
    created() {
        this.faqs.push({
            title: 'What is the Merlin Protocol testnet?',
            description: `This is the first usable version of Merlin Protocol. The purpose of the testnet is to make the initial version of the protocol accessible, allowing it to undergo stress testing and identify any bugs, as well as receive crucial feedback from the community to improve it in all aspects. Our goal is to establish ourselves as a reference in this specific industry right from the start.
The testnet showcases the key features of what will become the actual protocol, such as:
•	Purchasing all tokens within a fund with a single transaction.
•	Creating funds with different tokens using the Merlin Dao.
•	Utilizing the Merlin Dao to modify funds (e.g., adjusting the number of tokens, determining which tokens are included in the fund, or adjusting the proportions of different tokens within the fund).
Naturally, in the initial test version, certain parameters are limited by the environment in which we operate (for example, the number of usable tokens is restricted to the small list provided by Chainlink on Matic Mainnet). These parameters will be expanded, integrated, and improved upon the mainnet launch.

The beta version of Merlin Protocol revisits and improves upon all the features already seen in the test version.
In particular, various minor UX bugs have been resolved, while the initial test confirms the validity and robustness of the protocol.
Therefore, the previous FAQs remain valid, with the only exception being that operations are now conducted directly on POLYGON MATIC, making every transaction real.
`
        });
        this.faqs.push({
            title: 'What is Merlin token ( MRN )',
            description: `The MerlinToken is the backbone token of the MerlinProtocol ecosystem because it gives the right to participate and vote in the DAO.
The MerlinToken MRN will give the right to those who hold it to vote in the MerlinDAO, a decentralized organization that has the power to vote to modify the different criteria and parameters of the investment funds. Among these parameters we can think for example of the applicable fees, which and how many tokens to put in the portfolio, according to which proportions, the criteria according to which it is possible to invest in microcap tokens, the frequency of the token buyback from the Liquidity Pool through the fees generated by the protocol to distribute the tokens bought with the buyback for staking.
Examples of decisions that can be voted on by the DAO: 
-rebalancing parameters for funds (intended as a system) 
-proposals and acceptance of new oracles 
 -possible monetization models for the system 
-creation of special long term funds managed by the DAO 
-management of revenue fees from investments.
`
        });
        this.faqs.push({
            title: 'With which wallet can I use the Merlin Protocol testnet?',
            description: `This first version of Merlin Protocol test can be used only via MetaMask. In the next releases, Wallet Connect will be implemented to allow any wallet.`
        });
        this.faqs.push({
            title: 'Which network do I need to add to MetaMask to use the Merlin Protocol testnet?',
            description: `To use the Merlin Protocol testnet you need to add the Matic Mainnet network.`
        });
        this.faqs.push({
            title: 'How do I add the Matic Mainnet network on MetaMask?',
            description: `Here's a step-by-step guide to adding the Matic Mainnet of Polygon to your MetaMask. This guide is intended to be simple enough for someone with little to no experience.

Before we start, ensure that you have the MetaMask extension installed on your browser. If not, you can download and install it from the official MetaMask website.

**Step 1:** Click on the MetaMask icon on your browser's toolbar. This will open up the MetaMask extension.

**Step 2:** Log in to your MetaMask account. If you haven't created one yet, you'll need to do so. Remember to store your recovery phrase in a safe place - it's the only way to recover your account if you forget your password.

**Step 3:** Once you're logged in, you'll see your account page. Look at the top, where you'll see the Main Ethereum Network. This is a drop-down menu. Click on it.

**Step 4:** Scroll to the bottom of the drop-down menu and click on "Custom RPC". This will open a form for you to add a new network.

**Step 5:** In the "Custom RPC" form, enter the following details for the Matic Mainnet:

- Network Name: Matic Mainnet
- New RPC URL: https://polygon-rpc.com/
- Chain ID: 0x89
- Currency Symbol (optional): MATIC
- Block Explorer URL (optional): https://polygonscan.com/

Once you're done, click on "Save".

**Step 6:** You should now see "Matic Mainnet" listed in your networks drop-down, which means you've successfully added the Matic Mainnet to your MetaMask.

**Step 7:** To connect to the Matic Mainnet, simply go back to the drop-down menu and select "Matic Mainnet". Now you're connected to the Matic Mainnet of Polygon on MetaMask!

Always double-check the information when adding a new network to your MetaMask, as entering incorrect details can lead to loss of funds or assets.
`
        });
        this.faqs.push({
            title: `If you already have a wallet different from MetaMask and you don't want to create a new one, how can you import it?`,
            description: `To import a wallet different from MetaMask into your existing MetaMask account, follow these steps:
•	Open the MetaMask website and log in to your account.
•	In the top right corner of the screen, click on the menu icon with three horizontal lines to open the main menu.
•	In the main menu, select "Import Wallet" or "Import Account."
•	A screen will appear asking you to enter the seed phrase of your existing wallet. The seed phrase (or recovery phrase) is a series of words you received when you created your original wallet.
•	Enter your seed phrase in the provided field and click on "Import."
•	After completing the import, your original wallet will be integrated into your MetaMask account, and you will be able to use it on networks supported by MetaMask, including the Matic Mainnet network, which is essential for using the Merlin Protocol testnet.
Please note that the exact process may vary slightly depending on the specific version of MetaMask you are using. Make sure to follow the specific instructions provided by the version you are using to ensure a proper import of your wallet.
`
        });
        this.faqs.push({
            title: `Once the Matic Mainnet network has been successfully added, how can I obtain MATIC to pay for gas and use the protocol by executing transactions within it?`,
            description: `Polygon (formerly Matic) provides its own official faucets for users to get the test MATIC token on the Matic Mainnet.

Here is the step-by-step guide to use the official Polygon-Matic faucet:

**Step 1:** Ensure you're connected to the Matic Mainnet on MetaMask, following the steps provided in the previous response.

**Step 2:** Visit the official Polygon-Matic faucet URL: https://faucet.polygon.technology/

**Step 3:** You'll be prompted to connect your wallet. Click on the "Connect" button.

**Step 4:** MetaMask will open a new window asking for permission to connect with the faucet. Click on "Next", then on "Connect".

**Step 5:** Once your wallet is connected, you will see your account address in the top right corner of the faucet page.

**Step 6:** Now, you'll see an option to choose the network and token. Since you're on the Matic Mainnet, choose "Matic Mainnet" from the drop-down menu.

**Step 7:** Click on the "REQUEST 1 MATIC" button. 

**Step 8:** The faucet will process the transaction, and you should receive 1 test MATIC in your Matic Mainnet account shortly.

**Step 9:** You can confirm receipt of the test MATIC by checking your MetaMask wallet. Ensure that you're still connected to the Matic Mainnet, then check your balance.

This process may be repeated as needed.

`
        });
        this.faqs.push({
            title: `How can I receive test MRN and USD tokens on the Matic Mainnet network to use the Merlin Protocol testnet?`,
            description: `Once you have successfully installed MetaMask, added the Matic Mainnet network, and obtained test MATIC to execute transactions, you will be able to claim the tokens by connecting your wallet to the following section:  "FAUCET"
You can make a claim for each address. Additionally, if multiple wallets are used with the same IP address, only one of them will be counted. 
`
        });
        this.faqs.push({
            title: `What actions need to be taken within the testnet to receive an MRN airdrop?`,
            description: `"The MRN received as an airdrop will also be valid on the mainnet."
The airdrop will be received based on the following actions taken:
•	Participating in the voting on the Merlin Dao portal.
•	Buying and selling every fund available on the testnet.
A corresponding amount of 100 MRN will be received for each address that completes the aforementioned actions. If multiple addresses corresponding to the same IP address perform the same actions, only the wallet with the highest potential airdrop will be calculated.
`
        });
        this.faqs.push({
            title: `What is the trading volume ranking and how does it work?`,
            description: `Those who meet the conditions in the previous point will be able to be part of the trading volumes ranking of Merlin Protocol's funds. Will be counted the purchase and sales total volumes of the various funds available on the Merlin Protocol testnet made by a single address. 
The first 100 addresses will be rewarded with an additional AirDrop following these ranking positions:
 
- 5.000 MRN for 1st to 10th;
- 1.000 MRN for 11th to 50th;
- 250 MRN for 51th to 100th.
 
Obviously, if a single IP has multiple addresses, the airdrop will only be provided to the address that can get more tokens.
`
        });
        this.faqs.push({
            title: `If I find a bug on the testnet, how can I report it?`,
            description: `We believe the community is very important for the Merlin Protocol and can help us improve the protocol. If you find any bug please submit it form the appropriate section called "report a bug" and follow the instructions.
If you are the first user who reported a bug, you will get 2000 MRN airdrop.
`
        });

        this.openedFaqs.push(0);
    }

    checkifOpened(index: number) {
        return this.openedFaqs.indexOf(index) > -1;
    }

    toggleFaq(index: number) {
        var found = this.openedFaqs.indexOf(index);
        if(found == -1)
            this.openedFaqs.push(index);
        else
            this.openedFaqs.splice(found, 1);
    }

}
